<script setup>
   import { ref} from 'vue'


   var backgrounds = ['MerkleBiege.svg', 'MerkleBlue.svg', 'MerkleGray.svg', 'MerkleOrange.svg', 'MerklePurple.svg', 'MerkleTurqiose.svg'];
    var randomBG = backgrounds[Math.floor(Math.random()*backgrounds.length)];
    const loginPage = ref(null);




</script>

<template>
    <div id="loginPage" ref="loginPage" v-if="!login">
        <div id="formContainer">
            <div id="logo"></div>
            <div id="form">
                <div id="formTitle">
                <h1>Login</h1>
                </div>
                <div id="formBody">
                <div id="formInputs">
                    <div id="UserName">
                    <input type="text" placeholder="User Name" v-model="userName">
                    </div>
                    <div id="password">
                    <input type="password" placeholder="Password" v-model="password">
                    </div>
                </div>
                <div id="formButtons">
                <button id="loginButton" @click="login">Login</button>
                <button id="signupButton" @click="signup">Request Access</button>
                </div>
                </div>
            </div>
        </div>
        <div class="BG" id="MerkleOrange" v-if="randomBG === 'MerkleOrange.svg'"></div>
        <div class="BG" id="MerkleBlue" v-if="randomBG === 'MerkleBlue.svg'"></div>
        <div class="BG" id="MerkleGray" v-if="randomBG === 'MerkleGray.svg'"></div>
        <div class="BG" id="MerkleBiege" v-if="randomBG === 'MerkleBiege.svg'"></div>
        <div class="BG" id="MerkleTurqiose" v-if="randomBG === 'MerkleTurqiose.svg'"></div> 
        <div class="BG" id="MerklePurple" v-if="randomBG === 'MerklePurple.svg'"></div>
    </div>
</template>

<style scoped>

.BG{
    position:absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
}
#MerkleOrange{
    background-image: url('../assets/RandomBG/MerkleOrange.svg');
}
#MerklePurple{
    background-image: url('../assets/RandomBG/MerklePurple.svg');
}
#MerkleTurqiose{
    background-image: url('../assets/RandomBG/MerkleTurqiose.svg');
}
#MerkleGray{
    background-image: url('../assets/RandomBG/MerkleGray.svg');
}
#MerkleBlue{
    background-image: url('../assets/RandomBG/MerkleBlue.svg');
}
#MerkleBiege{
    background-image: url('../assets/RandomBG/MerkleBiege.svg');
}

#loginPage{
    position:absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
}
#logo{
    background-image: url('../assets/MerkleLogoPack/SVG/Merkle_M_Blue.svg');
    background-size: contain;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    width: 50%;
    height: 20%;
    margin: 0 auto;
     margin-bottom: 10%;
    margin-top: 5%;
    float: left;
    display: block;
}

    #formContainer{
        width: 500px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
         box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
         border-radius: 0px;
         margin: auto;
         left: 0px;
         right: 0px;
         margin-top: 10%;
         z-index: 1;
    }
    #form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
        height: 40%;
        margin-bottom: 10%;
        width: 100%;
        
    }

    #formBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #formInputs{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #formInputs > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    #formContainer > #form > #formBody > #formInputs > div > input{
        border-radius: 0px;
        background: #f1f1f1;
        /*box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        /*border: 1px solid #e5e5e9;*/
        border: none;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 10px;
        width: 70%;
    }
    #formContainer > #form > #formBody > #formInputs > div > input:focus{
        outline: none;
    }
    #loginButton{
        background-color: #f23a1d;
        color: white;
        border: none;
        border-radius: 0px;
        padding: 10px;
        margin: 10px;
        width: 70%;
        cursor: pointer;
        transition: 0.2s ease-in;
    }
    #loginButton:hover{
        background-color: #ff644c;
    }
    #signupButton{
        background:none;
        color: #AEAEBC;
        border: none;
        border-radius: 40px;
        padding: 10px;
        margin: 10px;
        width: 70%;
        cursor: pointer;
    }

    #signupButton:hover{
        color: #f23a1d;
    }

</style>