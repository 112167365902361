<script setup>

let login = false

</script>

<template>
  <div class="container" v-if="login">
    <!--- create a navigation with logo and three buttons -->
    <div id="logo"></div>
      <ul id="navOptions">
        <li><a to="/">Home</a></li>
        <li><a to="/about">About</a></li>
        <li><a to="/contact">Contact</a></li>
      </ul>
  </div>
</template>



<style scoped>
.container{
  padding: 10px;
  height: 50px;
  left: 0%;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  top: 0;
  position:fixed;
  background-color: white;
}
#logo{
  background-image: url('../assets/MerkleLogoPack/SVG/Merkle_Logo_Blue.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 80%;
  margin: 0 auto;
  top: 10%;
  margin-left: 5%;
  float: left;
  display: block;
}
#navOptions{
  display: flex;
  width: 55%;
  justify-content: space-around;
  float: right;
  font-size: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  top: 40%;
  user-select: none;
}
#navOptions li a:hover{
  color: #f23a1d;
  cursor: pointer;
}
</style>
